import React, { useState, useEffect } from 'react';
import { Text, Link, Image, Spinner, Box } from '@chakra-ui/react';

const ContentRenderer = ({ text }) => {
  const [images, setImages] = useState({});

  useEffect(() => {
    const fetchImageStatus = async () => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const imageStatusPromises = [];

      text.split('\n').forEach((paragraph) => {
        paragraph.split(' ').forEach((word) => {
          if (urlRegex.test(word)) {
            imageStatusPromises.push(
              fetch(word, { method: 'HEAD' })
                .then(response => {
                  const contentType = response.headers.get('Content-Type');
                  const isImage = contentType && contentType.startsWith('image/');
                  setImages((prev) => ({ ...prev, [word]: isImage }));
                })
                .catch(() => {
                  setImages((prev) => ({ ...prev, [word]: false }));
                })
            );
          }
        });
      });

      await Promise.all(imageStatusPromises);
    };

    fetchImageStatus();
  }, [text]);

  return text.split('\n').map((paragraph, pIndex) => (
    <Text key={pIndex} mb={2}>
      {paragraph.split(/(\*\*[^*]+\*\*|__[^_]+__|https?:\/\/[^\s]+)/g).map((segment, sIndex) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const boldRegex = /(\*\*([^*]+)\*\*|__(.+)__)/;

        if (urlRegex.test(segment)) {
          if (segment in images) {
            return images[segment] ? (
              <React.Fragment key={sIndex}>
                <Image src={segment} alt="Embedded" maxW="100%" my={2} />
                <br />
              </React.Fragment>
            ) : (
              <Link key={sIndex} href={segment} isExternal color="teal.300" mr={1}>
                {segment}
              </Link>
            );
          } else {
            return (
              <Box key={sIndex} display="inline-block">
                <Spinner size="xs" />
              </Box>
            );
          }
        } else if (boldRegex.test(segment)) {
          const boldText = segment.replace(/(\*\*|__)/g, '');
          return (
            <Text as="b" key={sIndex} fontSize="lg"> {/* Added fontSize="lg" */}
              {boldText}
            </Text>
          );
        } else {
          return segment + ' ';
        }
      })}
    </Text>
  ));
};

export default ContentRenderer;
